//
//
//
//
//
//
//
//
//

import { useRoute } from '@nuxtjs/composition-api';
import { useContent } from '@vsf-enterprise/amplience';
import { onSSR, useVSFContext } from '@vue-storefront/core';
import Vue from 'vue';

/*
 * This page is prepared for CMS dynamic content rendering.
 */
export default Vue.extend({
  name: 'CMSDynamicPage',
  setup(_props) {
    const route = useRoute();
    const { i18n: { locale } } = useVSFContext();
    const { id } = route.value.params;
    const { search, content } = useContent(id);
    onSSR(async () => {
      await search({
        url: id,
        locale: locale === 'default' ? 'en' : locale,
        preview: route.value.query.preview,
      });
    });
    return {
      content,
    };
  },
});
